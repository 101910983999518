// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

export enum Routing {
  Login = '/auth/login',

  /** Users */

  IdpUserCreate = 'idp/users',
  AccessUserCreate = 'user/create',

  /** Accounts */
  Wallets = '/ams/wallets',
  WalletsDrafts = '/ams/wallets-drafts',
  WalletsDraftsReviewed = '/ams/wallets-drafts/reviewed',
  WalletsDraftUpdate = '/ams/wallets-drafts/:id/:status',
  HistoryWallets = '/ams/history/wallets',
  WalletsLink = '/ams/links',
  QueryWallets = '/ams/query-wallets',
  WalletsCount = '/ams/wallets/count',

  /** Accounts */
  T2Accounts = '/ams/t2accounts',
  T2AccountsDrafts = '/ams/t2accounts-drafts',
  T2AccountsLinksDrafts = '/ams/t2accounts-links-drafts',
  T2AccountsLinks = '/ams/t2accounts/links',
  T2AccountsDraftsReviewed = '/ams/t2accounts-drafts/reviewed',
  T2AccountsDraftUpdate = '/ams/t2accounts-drafts/:id/:status',
  T2AccountLinksDraftsReviewed = '/ams/t2accounts-links-drafts/reviewed',
  T2AccountLinksDraftUpdate = '/ams/t2accounts-links-drafts/:id/:status',
  HistoryT2Accounts = '/ams/history/t2accounts',

  /** CountryCodes */
  CountryCodes = '/grs/countrycode',

  /** Power Of Attorney */
  CreatePOA = '/ams/poa',
  UpdatePOA = '/ams/poa',
  GetPOA = '/ams/poa/:id',
  ReviewedPOADraft = '/ams/poa-drafts/reviewed',
  UpdateStatusPOADraft = '/ams/poa-drafts/:id/:status',
  GetPOADraftsForScope = '/ams/poa-drafts',
  GetPOADraft = '/ams/poa-drafts/:id',

  /** Total */
  Total = '/ams/totalundermanagement',

  /** Whitelists */
  Whitelists = '/ams/whitelists',
  WhitelistsLinks = '/ams/whitelists/links',
  WhitelistDraftsReviewed = '/ams/whitelists-drafts/reviewed',
  WhitelistDraftUpdate = '/ams/whitelists-drafts/:id/:status',
  HistoryWhitelists = '/ams/history/whitelists',

  /** Entities */

  Entities = '/grs/entities',
  UpdateEntities = '/grs/entities/:id',
  HistoryEntities = '/grs/history/entities',
  Clients = '/grs/clients',
  HistoryClients = '/grs/history/clients',

  /** Networks */

  Networks = '/grs/networkinfos',
  HistoryNetworks = '/grs/history/networkinfos',

  /** Participants */
  Participants = '/grs/market-dlt',

  /** NCB */

  NCBS = '/grs/ncbs',

  /** Currencies */

  Currencies = '/grs/currencies',
  HistoryCurrencies = '/grs/history/currencies',

  /** Instructions */

  InstructionCreate = '/rvs/sirequest',
  InstructionCancel = '/instructions/cancel',
  AlreadyMatchedInstructionCreate = '/rvs/amrequest',
  ACOReimbursementCreate = '/rvs/immediatereimbursement',
  Instructions = '/ims/sis',
  Pvps = '/ims/pvps',
  InstructionDraftListGet = '/rvs/sidraft',
  InstructionDraftPost = '/rvs/sidraft',
  InstructionDraftDelete = '/rvs/sidraft',
  ACOBorrow = '/rvs/acobrr',

  /** Liquidity Transfer */

  CBDCOperationCreate = '/rvs/cbdc-operation-request',
  CBDCOperationGet = '/ims/cbdc-operations',
  CBDCOperationDraft = '/rvs/cbdc-operation-draft',
  CBDCOperationDraftDelete = '/rvs/cbdc-operation-draft',

  OperationsExtract = '/ims/operations',
  MovementsExtract = '/ams/movements',

  /** Payment Transfer */

  CBDCPaymentCreate = '/rvs/cbdc-payment-request',
  CBDCPaymentGet = '/ims/cbdc-payments',
  CBDCPaymentDraft = '/rvs/cbdc-payment-draft',
  CBDCPaymentDraftDelete = '/rvs/cbdc-payment-draft',

  PaymentExtract = '/ims/payments',

  /** CBDC Requests */
  CBDCRequest = 'octopus/rvs/cbdcrequest',

  /** Parameters */
  BusinessDate = '/grs/businessdate',
  BusinessWindows = '/grs/business-windows',
  GetBusinessWindowById = '/grs/business-windows/:id',
  TimeoutRollbackGet = '/grs/timeoutrollback',
  InstructionsRecycle = '/ims/recycleInstructions',
  GrsAssetType = '/grs/assetType',
  CbdcBDClosure = '/cbdcBDclosure',
  CaesProcessEvents = '/caes/processevents',
  CalatheaProcessEvents = '/calathea/processevents',

  /** Securities */
  Securities = '/grs/securities',

  CAES = '/caes/caevent',
  Calathea = '/calathea/cae',

  /** File upload */
  SIDraftUpload = '/rvs/sidraft/csv',
  LTDraftUpload = '/rvs/cbdc-operation-draft/csv',

  UploadMarketDLTConfiguration = '/grs/market-dlt'
}
